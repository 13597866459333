import {
    getNewReleasesListAdminAPI,
    getNewReleaseByIdAdminAPI,
    changeReleaseStatusAdminAPI,
    rejectReleaseAudioAdminAPI,
    deleteRejectedReleaseAPI,
    searchInReleasesAPI,
    rejectReasonsAdminAPI,
    ticketReasonsAdminAPI,
    deleteFileFromServerAPI,
    getUGCClaimOldAdminAPI,
    getUGCClaimRequestAdminAPI,
    getProfileLinkingRequestAdminAPI,
    getProfileLinkingOldAdminAPI,
    getPitchRequestAdminAPI,
    getPitchOldAdminAPI,
    updateUGCstatusAdminAPI,
    updateProfileLinkingstatusAdminAPI,
    updatePitchStatusAdminAPI
} from "./api"

export function getNewReleasesListAdmin(data) {
    return new Promise((resolve, reject) => {
        getNewReleasesListAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getNewReleaseByIdAdmin(data) {
    return new Promise((resolve, reject) => {
        getNewReleaseByIdAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getUGCClaimOldAdmin(data) {
    return new Promise((resolve, reject) => {
        getUGCClaimOldAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getUGCClaimRequestAdmin(data) {
    return new Promise((resolve, reject) => {
        getUGCClaimRequestAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function searchInReleases(data) {
    return new Promise((resolve, reject) => {
        searchInReleasesAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function changeReleaseStatusAdmin(data) {
    return new Promise((resolve, reject) => {
        changeReleaseStatusAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function rejectReleaseAudioAdmin(data) {
    return new Promise((resolve, reject) => {
        rejectReleaseAudioAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function deleteRejectedRelease(data) {
    return new Promise((resolve, reject) => {
        deleteRejectedReleaseAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function rejectReasonsAdmin(data) {
    return new Promise((resolve, reject) => {
        rejectReasonsAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function ticketReasonsAdmin(data) {
    return new Promise((resolve, reject) => {
        ticketReasonsAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function deleteFileFromServer(data) {
    return new Promise((resolve, reject) => {
        deleteFileFromServerAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getProfileLinkingRequestAdmin(data) {
    return new Promise((resolve, reject) => {
        getProfileLinkingRequestAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getProfileLinkingOldAdmin(data) {
    return new Promise((resolve, reject) => {
        getProfileLinkingOldAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getPitchRequestAdmin(data) {
    return new Promise((resolve, reject) => {
        getPitchRequestAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getPitchOldAdmin(data) {
    return new Promise((resolve, reject) => {
        getPitchOldAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function updateUGCstatusAdmin(data) {
    return new Promise((resolve, reject) => {
        updateUGCstatusAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function updateProfileLinkingstatusAdmin(data) {
    return new Promise((resolve, reject) => {
        updateProfileLinkingstatusAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function updatePitchStatusAdmin(data) {
    return new Promise((resolve, reject) => {
        updatePitchStatusAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}