import React, { useEffect, useState } from 'react'
import { Grid, Box, Paper, CircularProgress, Button } from "@material-ui/core"
import { useParams } from "react-router-dom"
import * as actions from "../Common/actions"
import * as releaseActions from "../Releases/actions"
import { toastError, toastSuccess } from '../../utils';
import moment from 'moment';
import SendIcon from '@material-ui/icons/Send';
import { API_URL } from '../../constants';
import SelectCMP from "react-select"

function ViewTicket(props) {
    const params = useParams();

    const [ticket, setTicket] = useState({})
    const [sendReply, setSendReply] = useState({
        ticket_id: params.id,
        reply: '',
        attachement: "",
        sent_by: "admin"
    })
    const [sendLoader, setSendLoader] = useState(false)
    const [rejectOptions, setRejectOptions] = useState([])

    useEffect(() => {
        if (params.id) {
            fetchTicketsById()
            onGetRejectOptions()
        }
    }, [])

    const onGetRejectOptions = () => {
        releaseActions.ticketReasonsAdmin().then(res => {
            setRejectOptions(Array.isArray(res.body) ? res.body.map(item => ({ label: item.reason, value: item.reason })) : [])
        })
    }

    const fetchTicketsById = () => {
        props.setLoader(true)
        actions.getTicketById(params.id).then(res => {
            props.setLoader(false)
            setTicket({ ...res.body, attachement: IsJsonString(res.body.attachement) ? JSON.parse(res.body.attachement) : res.body.attachement })
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const onSubmitReply = () => {
        if (!sendReply.reply) return toastError('Please enter a reply first.')
        setSendLoader(true)
        actions.replyTicket(sendReply).then(res => {
            setSendLoader(false)
            setSendReply({ ...sendReply, reply: "" })
            fetchTicketsById()
        }).catch(err => {
            setSendLoader(false)
            toastError(err.message)
        })
    }

    const keyPressed = e => {
        if (e.key === 'Enter' && e.shiftKey) {
            return
        }
        if (e.key === "Enter") {
            e.preventDefault();
            onSubmitReply()
        }
    }

    const onCloseTicket = (id) => {
        props.setLoader(true)
        actions.closeTicketAdmin({ ticket_id: id }).then(res => {
            props.setLoader(false)
            toastSuccess(res.message)
            fetchTicketsById()
        }).catch(err => {
            props.setLoader(false)
            toastError(err.message)
        })
    }

    const leftStyle = {
        padding: '8px 11px',
        borderRadius: 6,
        clear: 'both',
        float: 'left',
        width: "fit-content",
        fontSize: 15,
        background: 'rgb(245 245 245)',
        marginBottom: 26,
        maxWidth: 800,
        position: 'relative'
    }

    const rightStyle = {
        padding: '8px 11px',
        borderRadius: 5,
        clear: 'both',
        float: 'right',
        width: "fit-content",
        fontSize: 15,
        background: '#dadada',
        marginBottom: 26,
        maxWidth: 800,
        position: 'relative'
    }

    const { ticket_id = '', reason = '', date = '', reply = '', attachement = '', sent_by = '', status, replies = [], created_at = '' } = ticket || {}
    const viewAttachment = (attachement && attachement.split(',')) || []

    return (
        <Grid className="view-tickets-container">
            {status === 1 ? null : <Grid item xs={12}>
                <Box display='flex' justifyContent="space-between">
                    <div><h2>Ticket ID: {ticket_id}</h2></div>
                    <Button style={{ display: "block", background: "red", color: "white" }} onClick={() => onCloseTicket(ticket_id)}>Close ticket</Button>
                </Box>
            </Grid>}
            <Grid item xs={12}>
                <Paper>
                    <Box mt={2} mb={2}>
                        <Grid container>

                            <Grid item xs={12} sm={6} md={4}>
                                <Box p={2} className="box-div">
                                    <label>Reason</label>
                                    <p>{reason}</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Box p={2} className="box-div">
                                    <label>Date</label>
                                    <p>{moment(date).format('DD/MM/yyyy')}</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Box p={2} className="box-div">
                                    <label>Status</label>
                                    <p>{status ? 'Done' : 'Pending'}</p>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={2} p={2} className="box-div">
                                    <label>Attachments:</label>
                                    <div style={{ display: "flex", marginTop: 5, overflow: "auto" }}>
                                        {
                                            viewAttachment && Array.isArray(viewAttachment) && viewAttachment.map((item, index) => {
                                                return <a className="save-attachements" key={index} href={`${API_URL}/${item}`} download target="_blank">Attachment {index + 1}</a>
                                            })
                                        }
                                    </div>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box mb={2} p={2} className="box-div">
                                    <label>Ticket:</label>
                                    <p>{reply}</p>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '20px', height: "40px" }}>
                        <SelectCMP
                            options={rejectOptions}
                            // value={selectedTicketType}
                            placeholder="Select Reply"
                            onChange={e => setSendReply({ ...sendReply, reply: e.value })}
                        />
                    </Grid>

            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box mt={2} mb={2}>
                                    {
                                        replies && replies.length ? replies.map((item, key) => {
                                            // let attachements = (item.attachement && JSON.parse(item.attachement)) || [];
                                            // console.log(attachements, "attachements")
                                            // attachements = Array.isArray(attachements) && attachement.length ? attachement : [] || []
                                            return <div key={key} style={item.sent_by === 'admin' ? rightStyle : leftStyle}>
                                                <p style={{ fontWeight: 600, marginBottom: 6 }}>{item.sent_by === sent_by ? "User's Reply:" : "Your reply:"}</p>
                                                {/* <span style={{ flexWrap: "wrap", whiteSpace: "pre-wrap" }}>{
                                                    // item.reply.split(" ").map(replySplit => {
                                                    //     if(replySplit.includes("http")) {
                                                    //         return <a style={{color: "blue"}} href={replySplit} target="_blank" rel="noreferrer" >{replySplit}&nbsp;</a>
                                                    //     }
                                                    //     return <span>{replySplit}&nbsp;</span>
                                                    // })
                                                    }</span> */}
                                                    <span style={{ whiteSpace: 'pre-wrap' }}>{item.reply}</span>
                                                <span className={`ticket-reply-created ${item.sent_by === 'admin' ? 'right' : 'left'}`}>{moment(item.created_at).format('DD/MM/yyyy hh:mm A')}</span>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        {/* {attachements.map((item, key) => {
                                                            return <div className="attachment-div" key={key}>

                                                            </div>
                                                        })} */}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        }) : null
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box md={2} display="flex">
                                    <textarea
                                        type="text"
                                        placeholder="Type your reply..."
                                        value={sendReply.reply}
                                        onKeyPress={keyPressed}
                                        onChange={(e) => setSendReply({ ...sendReply, reply: e.target.value })}
                                        className="reply-ticket-input"
                                    />
                                    <div className="svg-div">
                                        <button onClick={() => onSubmitReply()} disabled={sendLoader}>{sendLoader ? <CircularProgress size={22} style={{ color: "white", marginTop: 3 }} /> : <SendIcon className="svg-send" />}</button>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid >
    )
}

export default ViewTicket
