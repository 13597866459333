import { apiGet, apiPost } from "../../utils"

export function getNewReleasesListAdminAPI(data) {
    return apiGet(`/api/getNewReleasesListAdmin`, data)
}

export function getNewReleaseByIdAdminAPI(id) {
    return apiGet(`/api/getNewReleaseByIdAdmin?release_id=${id}`)
}

export function searchInReleasesAPI(filters) {
    const { type = '', search = '' } = filters || {}
    return apiGet(`/api/searchInReleases?type=${type}&search=${search}`)
}

export function changeReleaseStatusAdminAPI(data) {
    return apiPost(`/api/changeReleaseStatusAdmin`, data)
}

export function rejectReleaseAudioAdminAPI(data) {
    return apiPost(`/api/rejectReleaseAudioAdmin`, data)
}

export function deleteRejectedReleaseAPI(data) {
    return apiPost(`/api/deleteRejectedRelease`, data)
}

export function rejectReasonsAdminAPI(data) {
    return apiGet(`/api/rejectReasonsAdmin`, data)
}

export function ticketReasonsAdminAPI(data) {
    return apiGet(`/api/ticketReasonsAdmin`, data)
}

export function getUGCClaimRequestAdminAPI(data) {
    return apiGet(`/api/getUGCClaimRequestAdmin`, data)
}

export function getUGCClaimOldAdminAPI(data) {
    return apiGet(`/api/getUGCClaimOldAdmin`, data)
}

export function deleteFileFromServerAPI(data) {
    return apiPost(`/api/deleteFileFromServer`, data)
}

export function getProfileLinkingRequestAdminAPI(data) {
    return apiGet(`/api/getProfileLinkingRequestAdmin`, data)
}

export function getProfileLinkingOldAdminAPI(data) {
    return apiGet(`/api/getProfileLinkingOldAdmin`, data)
}

export function getPitchRequestAdminAPI(data) {
    return apiGet(`/api/getPitchRequestAdmin`, data)
}

export function getPitchOldAdminAPI(data) {
    return apiGet(`/api/getPitchOldAdmin`, data)
}

export function updateUGCstatusAdminAPI(data) {
    return apiPost(`/api/updateUGCstatusAdmin`, data)
}

export function updateProfileLinkingstatusAdminAPI(data) {
    return apiPost(`/api/updateProfileLinkingstatusAdmin`, data)
}

export function updatePitchStatusAdminAPI(data) {
    return apiPost(`/api/updatePitchStatusAdmin`, data)
}