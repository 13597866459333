import { apiGet, apiPost } from "../../utils"

export function addRevenueAdminAPI(data) {
    return apiPost('/api/addRevenueAdmin', data)
}

export function getReportRequestAdminAPI(data) {
    return apiGet('/api/getReportRequestAdmin', data)
}

export function confirmReportDeliveryAdminAPI(data) {
    return apiPost('/api/confirmReportDeliveryAdmin', data)
}

export function closeTicketAdminAPI(data) {
    return apiPost('/api/closeTicketAdmin', data)
}

export function getUserListAdminAPI(filters) {
    const { search = '' } = filters || {}
    return apiGet(`/api/getUserListAdmin?search=${search}`)
}

export function getUserDetailsByIdForAdminAPI(data) {
    return apiGet(`/api/getUserDetailsByIdForAdmin?user_id=${data || 9}`)
}

export function revokeUnrevokeUserForAdminAPI(data) {
    return apiPost('/api/revokeUnrevokeUserForAdmin', data)
}

export function getTicketListForAdminAPI(data) {
    const { sort = '', search = '' } = data || {}
    return apiGet(`/api/getTicketListForAdmin?sort=${sort}&search=${search}`)
}

export function getTicketByIdAPI(ticket_id) {
    return apiGet(`/api/getTicketById?ticket_id=${ticket_id}`)
}

export function sendInviteAPI(data) {
    return apiPost(`/api/sendInvite`, data)
}

export function replyTicketAPI(data) {
    return apiPost(`/api/replyTicket`, data)
}

export function getWithdrawRequestAdminAPI(data) {
    return apiGet(`/api/getWithdrawRequestAdmin`, data)
}

export function getSentInvitationsAdminAPI(data) {
    const { limit = 30, offset = 0 } = data || {}
    return apiGet(`/api/getSentInvitationsAdmin?limit=${limit}&offset=${offset}`, data)
}

export function sentMoneyByAdminAPI(data) {
    return apiPost(`/api/sentMoneyByAdmin`, data)
}

export function getAllArtistNamesAdminAPI(filters) {
    const { limit = 10, offset = 0, search = '' } = filters || {}
    return apiGet(`/api/getAllArtistNamesAdmin?limit=${limit}&offset=${offset}&search=${search}`, filters)
}

export function countMeterAdminAPI(data) {
    return apiGet(`/api/countMeterAdmin`, data)
}

export function checkAwayStatusAPI(data) {
    return apiGet(`/api/checkAwayStatus`, data)
}

export function changeAwayStatusAdminAPI(data) {
    return apiPost(`/api/changeAwayStatusAdmin`, data)
}

export function updateAlbumArtworkAdminAPI(data) {
    return apiPost(`/api/updateAlbumArtworkAdmin`, data)
}

export function sendSpecificNotificationsAdminAPI(data) {
    return apiPost(`/api/sendSpecificNotificationsAdmin`, data)
}

export function uploadAPI(data) {
    const data1 = new FormData();
    data1.append("image", data)
    return apiPost(`/api/upload`, data1)
}
